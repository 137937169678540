import Base = require("Everlaw/Base");
import DocumentGroupFilter = require("Everlaw/DocumentGroupFilter");
import DocumentGroupType = require("Everlaw/DocumentGroupType");
import Eql = require("Everlaw/Eql");
import Property = require("Everlaw/Property");
import { DedupeType } from "Everlaw/Search/DedupeType";
import * as Preference from "Everlaw/Preference";
import * as Project from "Everlaw/Project";

/**
 * Functionality for manipulating or identifying characteristics of EQL.
 */

/**
 * This interface can be used to capture any information about modifications made to a Logical
 * Property (And, Or). These modifications are implemented as wrapper properties (see Property.ts)
 */
export interface LogicalWrapperOptions {
    groupType: DocumentGroupType;
    filter: DocumentGroupFilter;
    samplePercent: number; // if equal to 100, no sampling occurs
    sampleSeed?: number;
    dedupeType: DedupeType;
    property?: Eql.Any; // the unwrapped property
}

export function defaultLogicalWrapperOptions(excludeDuplicates = false): LogicalWrapperOptions {
    return {
        groupType: DocumentGroupType.NoGroup,
        filter: DocumentGroupFilter.NoFilter,
        samplePercent: 100,
        sampleSeed: Math.floor(Math.random() * 500000),
        dedupeType: excludeDuplicates ? DedupeType.HideAll : DedupeType.ShowAll,
    };
}

export function applyDefaultSearchGroupingToEql(eql: Eql.Any): Eql.Any {
    if (!Project.CURRENT) {
        return eql;
    }
    const defaultSearchGroupingOptions: LogicalWrapperOptions = defaultSearchGroupingWrapperOptions(
        Project.CURRENT?.dedupeSearches,
    );
    defaultSearchGroupingOptions.property = eql;
    return wrapLogicalProp(defaultSearchGroupingOptions);
}

export function defaultSearchGroupingWrapperOptions(
    excludeDuplicates = false,
): LogicalWrapperOptions {
    const defaultPref = Preference.SEARCH.defaultGrouping.get();
    if (defaultPref.useDefault) {
        const group = DocumentGroupType.getGroupTypeFromName(defaultPref.defaultGroup);
        const filter = DocumentGroupFilter.getFilterFromName(defaultPref.defaultFilter);
        return {
            groupType: group,
            filter: filter,
            samplePercent: 100,
            sampleSeed: Math.floor(Math.random() * 500000),
            dedupeType: excludeDuplicates ? DedupeType.HideAll : DedupeType.ShowAll,
        };
    }
    return defaultLogicalWrapperOptions(excludeDuplicates);
}

/**
 * Returns a shallow copy of the provided options, clearing out the LogicalWrapperOptions#property.
 * This is useful for transferring chosen options between logical search terms and their
 * LogicalOptionsDialogs.
 */
export function copyOptions(options: LogicalWrapperOptions): LogicalWrapperOptions {
    const copy = { ...options };
    copy.property = undefined;
    return copy;
}

export function unwrapLogicalProp(eql: Eql.Any): LogicalWrapperOptions {
    const options = defaultLogicalWrapperOptions();
    // inclusiveEmails is technically not a GroupedWrapper (on the front-end), but if used, it
    // should occur in place of a GroupedWrapper (see Property.ts)
    if (Property.isGroupedWrapper(eql.property.EQL_NAME)) {
        const wrapper = <Property.GroupedWrapper>eql;
        options.groupType = Base.get(DocumentGroupType, wrapper.args.groupType);
        options.filter = Base.get(DocumentGroupFilter, wrapper.property.EQL_NAME);
        eql = wrapper.args.property;
    } else if (eql instanceof Property.InclusiveEmails) {
        options.groupType = DocumentGroupType.Threading;
        options.filter = DocumentGroupFilter.InclusiveEmails;
        eql = eql.args;
    } else if (eql instanceof Property.ExactEmailDuplicates) {
        options.groupType = DocumentGroupType.ExactDuplicates;
        options.filter = DocumentGroupFilter.ExactEmailDuplicates;
        eql = eql.args;
    }
    if (eql instanceof Property.RandomSample) {
        options.samplePercent = eql.args.percent;
        options.sampleSeed = eql.args.seed;
        eql = eql.args.property;
    }
    if (eql instanceof Property.ExcludeDuplicates) {
        options.dedupeType = DedupeType.HideAll;
        eql = eql.args;
    } else if (eql instanceof Property.IncludeOneDuplicate) {
        options.dedupeType = DedupeType.DedupeSearch;
        eql = eql.args;
    } else {
        options.dedupeType = DedupeType.ShowAll;
    }
    options.property = eql;
    return options;
}

// Pass in the property you want to wrap as options.property
export function wrapLogicalProp(options: LogicalWrapperOptions): Eql.Any {
    let eql = options.property;
    if (!eql) {
        return null;
    }

    // find the appropriate widget name
    let not = null;
    if (eql instanceof Property.Not) {
        not = eql;
        eql = eql.args;
    }
    let termName: string = null;
    if ((eql instanceof Property.And || eql instanceof Property.Or) && eql.hasWidget()) {
        termName = eql.property.EQL_NAME;
        eql.termName = termName;
    }
    if (not && termName && not.hasWidget(termName)) {
        not.termName = termName;
    }
    eql = not || eql;
    termName = termName || "and";

    // wrap the eql
    if (options.dedupeType.isDedupeSearch()) {
        eql = new Property.IncludeOneDuplicate(eql, termName);
    } else if (options.dedupeType.isHideAll()) {
        eql = new Property.ExcludeDuplicates(eql, termName);
    }
    if (options.samplePercent !== 100) {
        eql = new Property.RandomSample(
            {
                property: eql,
                percent: options.samplePercent,
                seed: options.sampleSeed,
            },
            termName,
        );
    }
    if (!DocumentGroupType.NoGroup.equals(options.groupType)) {
        if (options.filter === DocumentGroupFilter.InclusiveEmails) {
            eql = new Property.InclusiveEmails(eql, termName);
        } else if (options.filter === DocumentGroupFilter.ExactEmailDuplicates) {
            eql = new Property.ExactEmailDuplicates(eql, termName);
        } else {
            const Prop = Property.eqlNameToProp(options.filter.id);
            eql = new Prop(
                {
                    property: eql,
                    groupType: options.groupType.id,
                },
                termName,
            );
        }
    }
    return eql;
}

/**
 * Returns true if the corresponding eql is deduped at the top level (around the outermost and/or)
 */
export function isDeduped(eqlString: string): boolean {
    const unwrappedProperty = unwrapLogicalProp(Property.jsonToEql(JSON.parse(eqlString)));
    return unwrappedProperty.dedupeType.isDedupeOrHideAll();
}

/**
 * returns true if somewhere in the tree, treating eql as the root,
 * there is a term that has the widget widgetName
 */
export function eqlTreeContainsWidget(eql: Eql.Any, widgetName: string): boolean {
    if (eql.hasWidget(widgetName)) {
        return true;
    }
    if (eql instanceof Property.And || eql instanceof Property.Or) {
        for (const child of eql.args) {
            if (eqlTreeContainsWidget(child, widgetName)) {
                return true;
            }
        }
    } else {
        const unwrappedEql = getUnwrappedEql(eql);
        if (unwrappedEql !== eql && eqlTreeContainsWidget(unwrappedEql, widgetName)) {
            return true;
        }
    }
    return false;
}

export function eqlTreeContains(eql: Eql.Any, checkEql: (eql: Eql.Any) => boolean): boolean {
    if (checkEql(eql)) {
        return true;
    }
    if (eql instanceof Property.And || eql instanceof Property.Or) {
        for (const child of eql.args) {
            if (eqlTreeContains(child, checkEql)) {
                return true;
            }
        }
    } else {
        const unwrappedEql = getUnwrappedEql(eql);
        if (unwrappedEql !== eql && eqlTreeContains(unwrappedEql, checkEql)) {
            return true;
        }
    }
    return false;
}

/**
 * returns the wrapped eql. Does not unwrap logical operators, such as AND, OR
 * If it cannot unwrap the eql, will return the same eql.
 */
function getUnwrappedEql(eql: Eql.Any): Eql.Any {
    if (Property.isGroupedWrapper(eql.property.EQL_NAME)) {
        const wrapper = <Property.GroupedWrapper>eql;
        eql = wrapper.args.property;
    } else if (
        eql instanceof Property.InclusiveEmails
        || eql instanceof Property.ExactEmailDuplicates
    ) {
        eql = eql.args;
    }
    if (eql instanceof Property.RandomSample) {
        eql = eql.args.property;
    }
    if (eql instanceof Property.ExcludeDuplicates || eql instanceof Property.IncludeOneDuplicate) {
        eql = eql.args;
    }
    return eql;
}
