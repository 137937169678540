import Base = require("Everlaw/Base");
import * as DocumentGroupType from "Everlaw/DocumentGroupType";
/**  The Manage DuplicatesRadio either has 2 or 3 options depending on if project-wide deduplication is set.
 *
 *     If project-wide-deduplication is true then
 *      (Hide All(default), Dedupe Search, Show all)
 *    else
 *      (Dedupe Search, Show all(default))
 *
 *  Hide All is disabled if the search term that this dialog is attached to is not the outermost layer.
 *  Hide All is enabled, regardless of the project setting, if it is set in the current search options.
 *  Otherwise the dialog would be unable to show the actual search settings for some current searches.
 */
class DedupeType extends Base.Object {
    get className() {
        return "DedupeType";
    }
    static HideAll = DedupeType.init(
        `Hide all project ${DocumentGroupType.getDuplicateGroupString().toLowerCase()}`,
    );
    static DedupeSearch = DedupeType.init("Deduplicate within search hits");
    static ShowAll = DedupeType.init(
        `Show all project ${DocumentGroupType.getDuplicateGroupString().toLowerCase()}`,
    );

    private static init(id: string) {
        const groupType = new DedupeType(id);
        Base.add(groupType);
        return groupType;
    }

    protected constructor(public override id: string) {
        super({ id });
    }
    override display() {
        return this.id;
    }
    isShowAll() {
        return this.id === DedupeType.ShowAll.id;
    }
    isDedupeSearch() {
        return this.id === DedupeType.DedupeSearch.id;
    }
    isHideAll() {
        return this.id === DedupeType.HideAll.id;
    }
    isDedupeOrHideAll() {
        return this.isDedupeSearch() || this.isHideAll();
    }
}

export { DedupeType };
