import Dom = require("Everlaw/Dom");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import TextBox = require("Everlaw/UI/TextBox");
import UI = require("Everlaw/UI");
import UI_Validated = require("Everlaw/UI/Validated");

class InputDialog extends QueryDialog implements UI.WidgetWithTextBox {
    // prompt, onSubmit, and onCancel can be updated before each show;
    // the others are fixed
    placeholder: string;
    placeholderMessage: string;
    value: string;
    autoclear: boolean;
    textIconName: string;
    textBox: UI_Validated.Text;
    iconNode: Dom.Nodeable;
    constructor(params: any) {
        super(params);
        const _body = Dom.div({ class: params.prompt ? "text-input-container" : "" });
        if (this.textIconName) {
            this.iconNode = Dom.div();
        }
        this.textBox = new UI_Validated.Text(
            Object.assign(
                {
                    name: this.placeholder || "",
                    placeholderMessage: this.placeholderMessage || "",
                    textBoxAriaLabel: params.textBoxAriaLabel,
                    textBoxLabelContent: params.textBoxLabelContent,
                    textBoxLabelPosition: params.textBoxLabelPosition,
                    width: params.width,
                    value: this.value,
                    validator: params.submitValidator,
                    invalid: params.invalid,
                    invalidMessage: params.invalidMessage,
                    displayErrorMessage: params.displayErrorMessage,
                    validateIfTextUnchanged: params.validateIfTextUnchanged,
                },
                params.formParams,
            ),
        );
        this.accessibleSubmit && this.accessibleSubmit.addFormToValidated(this.textBox);
        this._toDestroy.push(this.textBox);
        Dom.place(this.textBox.node, _body);
        this.body = _body;
    }
    override submit() {
        if (this.onSubmit(this.textBox.getValue(), this)) {
            this.hide();
        }
    }
    override _cancelWrapper() {
        if (this.onCancel(this.textBox.getValue(), this)) {
            this.hide();
        }
    }
    override show() {
        if (this.autoclear) {
            this.textBox.setValue(this.value || "");
        }
        // Unsafe cast to make this file pass strict TS checks.
        super.show.apply(this, arguments as unknown as []);
        this.textBox.focus();
    }
    setTextBoxAriaLabel(ariaLabel: string) {
        this.textBox.setTextBoxAriaLabel(ariaLabel);
    }
    setTextBoxLabelContent(labelContent: Dom.Content) {
        this.textBox.setTextBoxLabelContent(labelContent);
    }
    setTextBoxLabelPosition(position: TextBox.LabelPosition) {
        this.textBox.setTextBoxLabelPosition(position);
    }
}
InputDialog.prototype.prompt = "Enter value: ";
InputDialog.prototype.placeholder = "";
InputDialog.prototype.autoclear = true;

module InputDialog {}

export = InputDialog;
