import { AllConfiguredCategoryCodes } from "Everlaw/Review/ReviewAssistantCodingTab";
import { addToast, ToastBoxLocation } from "Everlaw/ToastBoxManager";
import { Feedback, FeedbackStylingProps, IconSize } from "Everlaw/UI/Feedback";
import { Icon, PopoverPlacement } from "design-system";
import * as React from "react";
import * as Rest from "Everlaw/Rest";
import { useCallback, useMemo } from "react";
export interface LlmFeedbackProps extends FeedbackStylingProps {
    /**
     * Valid ids present in ESI.GenerativeAssistantCompletion. Set it as [] if the object's
     * completionIds is undefined. We hide the component if this equals [].
     */
    completionIds: number[];
    /**
     * List of enabled codes to provide context on feedback.
     */
    codingAssistantCodes?: AllConfiguredCategoryCodes[];
    toastBoxLocation?: ToastBoxLocation;
}

interface RestParamsProps {
    completionIds: number[];
    rating: boolean;
    freeformFeedback?: string;
    context?: string[];
}

export function LlmFeedback({
    completionIds,
    iconSize = IconSize.DEFAULT,
    popoverPlaceholder = "Your feedback helps us improve Everlaw AI",
    popoverPlacement = [PopoverPlacement.BOTTOM_START, PopoverPlacement.TOP_START],
    toastBoxLocation = ToastBoxLocation.CENTER,
    thumbsUpTextBoxPlaceholder = "What did you like about this response?",
    thumbsDownTextBoxPlaceholder = "How could this response be improved?",
    codingAssistantCodes = [],
}: LlmFeedbackProps): JSX.Element {
    const options: string[] = useMemo(() => {
        const arr: string[] = [];
        codingAssistantCodes?.forEach((obj) => {
            const categoryName = obj.category.name;
            obj.enabledCodes.forEach((code) => arr.push(`${categoryName}.${code.name}`));
        });
        return arr;
    }, [codingAssistantCodes]);

    const onSubmitHandler = useCallback(
        (rating: boolean, textFeedback?: string, context?: string[]) => {
            const url = `llm/submitFeedback.rest`;
            const restParams: RestParamsProps = {
                completionIds: completionIds,
                rating: rating,
            };
            if (textFeedback !== undefined) {
                restParams.freeformFeedback = textFeedback;
            }
            if (context !== undefined) {
                restParams.context = context;
            }
            Rest.post(url, restParams)
                .then(() => {
                    addToast({
                        title: "Feedback submitted",
                        children: "We appreciate your feedback, which helps us improve Everlaw AI.",
                        location: toastBoxLocation,
                        icon: <Icon.CircleCheck size={20} aria-label={"Feedback submitted"} />,
                    });
                })
                .catch((e: Rest.Failed) => {
                    if (e.message) {
                        e.show();
                    }
                });
        },
        [completionIds, toastBoxLocation],
    );

    return (
        <>
            {completionIds.length !== 0 && (
                <Feedback
                    key={"feedback-" + completionIds.toString()}
                    onSubmit={onSubmitHandler}
                    iconSize={iconSize}
                    contextOptions={options}
                    popoverPlaceholder={popoverPlaceholder}
                    popoverPlacement={popoverPlacement}
                    thumbsUpTextBoxPlaceholder={thumbsUpTextBoxPlaceholder}
                    thumbsDownTextBoxPlaceholder={thumbsDownTextBoxPlaceholder}
                />
            )}
        </>
    );
}
