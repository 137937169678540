import Dom = require("Everlaw/Dom");
import Preference = require("Everlaw/Preference");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import ActionNode = require("Everlaw/UI/ActionNode");
import Icon = require("Everlaw/UI/Icon");
import DocumentGroupType = require("Everlaw/DocumentGroupType");
import { BatchServiceNotificationVisibility, Chain } from "Everlaw/Preference";
import Tooltip = require("Everlaw/UI/Tooltip");
import { addToastWrapper } from "Everlaw/ToastBoxManager";

/**
 * Should parallel Type in TaskNotification.java.
 */
export enum NotificationType {
    NEAR_DUPE_NOTIFY = "NEAR_DUPE_NOTIFY",
    THREADING_NOTIFY = "THREADING_NOTIFY",
}

const GROUP_TO_NOTIFICATION_TYPE = new Map<DocumentGroupType, NotificationType>();
GROUP_TO_NOTIFICATION_TYPE.set(DocumentGroupType.NearDuplicates, NotificationType.NEAR_DUPE_NOTIFY);
GROUP_TO_NOTIFICATION_TYPE.set(DocumentGroupType.Threading, NotificationType.THREADING_NOTIFY);
GROUP_TO_NOTIFICATION_TYPE.set(
    DocumentGroupType.AllConversations,
    NotificationType.THREADING_NOTIFY,
);

const NOTIFICATION_TYPE_TO_PREFERENCE = new Map<
    NotificationType,
    Chain<BatchServiceNotificationVisibility>
>();
NOTIFICATION_TYPE_TO_PREFERENCE.set(
    NotificationType.NEAR_DUPE_NOTIFY,
    Preference.NEAR_DUPE.showToastNotifications,
);
NOTIFICATION_TYPE_TO_PREFERENCE.set(
    NotificationType.THREADING_NOTIFY,
    Preference.THREADING.showToastNotifications,
);

export function groupToNotificationType(groupType: DocumentGroupType): NotificationType {
    return GROUP_TO_NOTIFICATION_TYPE.get(groupType);
}

export function notificationTypeToPreference(
    notificationType: NotificationType,
): Chain<BatchServiceNotificationVisibility> {
    return NOTIFICATION_TYPE_TO_PREFERENCE.get(notificationType);
}

abstract class AbstractStatusHandler {
    protected isShown: boolean;
    protected type: NotificationType;

    protected constructor(type: NotificationType) {
        this.type = type;
        this.isShown = false;
    }

    abstract display(): string;
    abstract finishedMsg(): string;
    abstract inProgressMsg(): string;
    abstract toastWarningMsg(): string;
    abstract toastLearnMoreLink(): HTMLAnchorElement;
    abstract unfinishedWarningMsg(): string;
    abstract tooltipLearnMoreLink(): HTMLAnchorElement;

    checkStatusAndNotify() {
        if (this.isShown) {
            return;
        }
        if (this.getPreference() === BatchServiceNotificationVisibility.ALWAYS_SHOW) {
            getBatchServiceStatuses().then((statuses) => {
                if (statuses.isUnfinished(this.type)) {
                    this.createUnfinishedToast();
                }
            });
        }
    }

    private getPreference(): BatchServiceNotificationVisibility {
        return notificationTypeToPreference(this.type).get();
    }

    async createUnfinishedToast() {
        const requestedNotification = await this.isNotificationRequested();
        const notificationOption = requestedNotification
            ? this.optedInByline()
            : this.emailUponCompleteByline();
        if (this.isShown) {
            return;
        }
        const link = Dom.div({}, this.toastLearnMoreLink());
        const body = [
            Dom.div({ class: "toast__body" }, [this.toastWarningMsg(), link]),
            notificationOption,
        ];
        const title = `${this.display()} in progress`;
        addToastWrapper({ title, body });
        this.isShown = true;
    }

    private isNotificationRequested(): Promise<boolean> {
        return Rest.get(Project.CURRENT.url("isNotificationRequested.rest"), {
            type: this.type,
        }).then((data) => !!data.requestedNotification);
    }

    private emailUponCompleteByline(): HTMLElement {
        const id = "batch-service-notification-opt-in-byline";
        return Dom.div(
            { id },
            ActionNode.textAction("Notify me upon completion", () => {
                this.notifyOnComplete();
                ga_event(`${this.display()} Notification`, "click", "email");
                Dom.place(this.optedInByline(), Dom.byId(id), "replace");
            }).node,
        );
    }

    private optedInByline(): HTMLElement {
        return Dom.div({}, [
            new Icon("circle-check-filled-green-20").node,
            Dom.span({ class: "toast__byline" }, "You will be emailed upon completion"),
        ]);
    }

    private notifyOnComplete() {
        Rest.post(Project.CURRENT.url("notifyOnComplete.rest"), { type: this.type });
    }

    reviewWindowWarningElement(): HTMLElement {
        const warning = Dom.span(
            { class: "review-window__grouping-status-warning" },
            this.inProgressMsg(),
        );
        new Tooltip(warning, this.warningTooltipBody());
        return warning;
    }

    warningTooltipBody(): HTMLElement {
        const body = Dom.div({ class: "tooltip-warning-body" }, this.unfinishedWarningMsg());
        const link = Dom.div({}, this.tooltipLearnMoreLink());
        return Dom.div({}, [body, link]);
    }
}

class NearDupeStatusHandler extends AbstractStatusHandler {
    constructor() {
        super(NotificationType.NEAR_DUPE_NOTIFY);
    }

    override display(): string {
        return "Near duplicate grouping";
    }

    override finishedMsg(): string {
        return (
            "All near duplicate groups are up to date. Regrouping occurs upon new uploads and "
            + "document deletions."
        );
    }

    override inProgressMsg(): string {
        return "Regrouping in progress";
    }

    override toastWarningMsg(): string {
        return (
            "Near duplicate groups may be incomplete or misrepresented in your searches, the"
            + " review window, or the duplicates panel during this time."
        );
    }

    override toastLearnMoreLink(): HTMLAnchorElement {
        return Dom.a(
            {
                href:
                    "https://support.everlaw.com/hc/en-us/articles/"
                    + "360050656232-Project-Statuses#h_01EMSWN3ATACB8HEXBN9HMG76T",
                target: "_blank",
                class: "near-dupe-help-link",
            },
            "Learn more",
        );
    }

    override unfinishedWarningMsg(): string {
        return (
            "Near duplicate groups are currently being updated to incorporate changes from new "
            + "or modified documents. Groups may be outdated during this time."
        );
    }

    override tooltipLearnMoreLink(): HTMLAnchorElement {
        return Dom.a(
            {
                href: "https://support.everlaw.com/hc/en-us/articles/360050656232#h_01H7JQ91RS1FTPBKVM2CH4XGRK",
                target: "_blank",
                class: "near-dupe-help-link",
            },
            "Learn more",
        );
    }
}

class ThreadingStatusHandler extends AbstractStatusHandler {
    constructor() {
        super(NotificationType.THREADING_NOTIFY);
    }

    override display(): string {
        return "Threading updates";
    }

    override finishedMsg(): string {
        return (
            "All threads are up to date. Rethreading occurs upon new uploads, productions, "
            + "document deletions, or metadata overlays."
        );
    }

    override inProgressMsg(): string {
        return "Rethreading in progress";
    }

    override toastWarningMsg(): string {
        return (
            "Email threads are currently being updated to incorporate changes from new or "
            + "modified documents."
        );
    }

    override toastLearnMoreLink(): HTMLAnchorElement {
        return ThreadingStatusHandler.emailThreadingStatusLink();
    }

    override unfinishedWarningMsg(): string {
        return (
            "Email threads are currently being updated to incorporate changes from new or modified "
            + "documents. Threads may be outdated during this time."
        );
    }

    override tooltipLearnMoreLink(): HTMLAnchorElement {
        return ThreadingStatusHandler.emailThreadingStatusLink();
    }

    private static emailThreadingStatusLink(): HTMLAnchorElement {
        return Dom.a(
            {
                href:
                    "https://support.everlaw.com/hc/en-us/articles/"
                    + "360050656232-Project-Statuses#h_01EMSWN3ATACB8HEXBN9HMG76T",
                target: "_blank",
                class: "near-dupe-help-link",
            },
            "Learn more",
        );
    }
}

const HANDLER_BY_TYPE = new Map<NotificationType, AbstractStatusHandler>();
HANDLER_BY_TYPE.set(NotificationType.NEAR_DUPE_NOTIFY, new NearDupeStatusHandler());
HANDLER_BY_TYPE.set(NotificationType.THREADING_NOTIFY, new ThreadingStatusHandler());

export function checkStatusAndNotify(type: NotificationType) {
    HANDLER_BY_TYPE.get(type).checkStatusAndNotify();
}

export function createThreadingUnfinishedToast() {
    HANDLER_BY_TYPE.get(NotificationType.THREADING_NOTIFY).createUnfinishedToast();
}

export function getMessage(type: NotificationType, isUnfinished: boolean): string {
    const handler: AbstractStatusHandler = HANDLER_BY_TYPE.get(type);
    return isUnfinished ? handler.unfinishedWarningMsg() : handler.finishedMsg();
}

export function reviewWindowWarning(type: NotificationType): HTMLElement {
    return HANDLER_BY_TYPE.get(type).reviewWindowWarningElement();
}

export function inProgressMsg(type: NotificationType): string {
    return HANDLER_BY_TYPE.get(type).inProgressMsg();
}

export function warningTooltipBody(type: NotificationType): HTMLElement {
    return HANDLER_BY_TYPE.get(type).warningTooltipBody();
}

export class BatchServiceStatuses {
    private isUnfinishedByType = new Map<NotificationType, boolean>();

    constructor(map: { [s: string]: boolean }) {
        for (const [type, unfinished] of Object.entries(map)) {
            this.isUnfinishedByType.set(NotificationType[type] as NotificationType, unfinished);
        }
    }

    isUnfinished(type: NotificationType) {
        return this.isUnfinishedByType.get(type);
    }
}

export function getBatchServiceStatuses(): Promise<BatchServiceStatuses> {
    if (!Project.CURRENT) {
        return Promise.resolve(new BatchServiceStatuses({}));
    }
    return Rest.get(Project.CURRENT.url("batchServiceStatuses.rest")).then((data) => {
        return new BatchServiceStatuses(data);
    });
}
