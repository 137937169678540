import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonWidth,
    DropdownMenu,
    EverColor,
    Icon,
    IconButton,
    Popover,
    PopoverPlacement,
    TextArea,
} from "design-system";
import * as React from "react";
import { Dispatch, SetStateAction, useRef, useState } from "react";

const CHAR_LIMIT = 3000;
export enum IconSize {
    DEFAULT = 24,
    SMALL = 20,
}
interface FeedbackProps extends FeedbackStylingProps {
    /**
     * Handler to submit feedback to the appropriate endpoint.
     *
     * @param rating boolean that signifies like/dislike.
     */
    onSubmit: (rating: boolean, textFeedback?: string, context?: string[]) => void;
    /**
     * Options for multi-dropdown for adding context to the feedback. Used for cases concerning
     * multiple entities such as codes.
     */
    contextOptions?: string[];
}

export interface FeedbackStylingProps {
    iconSize?: IconSize;
    /**
     * Text to be displayed below the textbox in the popover.
     */
    popoverPlaceholder?: string;
    /**
     * Placement(s) of the popover relative to the target. If an array of placements is given and
     * there is not enough space on screen for the first placement, one of the placements listed
     * after the first will be chosen based on whichever one has the least amount of overflow. This
     * means that placement ordering from index 1 and greater will not necessarily be respected.
     */
    popoverPlacement?: PopoverPlacement | PopoverPlacement[];
    /**
     * Placeholder text for the textbox when the user clicks on the thumbs-up icon.
     */
    thumbsUpTextBoxPlaceholder?: string;
    /**
     * Placeholder text for the textbox when the user clicks on the thumbs-down icon.
     */
    thumbsDownTextBoxPlaceholder?: string;
}

export function Feedback({
    onSubmit,
    iconSize = IconSize.DEFAULT,
    popoverPlaceholder = "Your feedback helps improve quality of the product",
    popoverPlacement = [PopoverPlacement.BOTTOM_START, PopoverPlacement.TOP_START],
    thumbsUpTextBoxPlaceholder = "What did you like about this?",
    thumbsDownTextBoxPlaceholder = "How could this be improved?",
    contextOptions = [],
}: FeedbackProps): JSX.Element {
    const [showFeedbackPopover, setShowFeedbackPopover] = useState(false);
    const [rating, setRating] = useState<boolean | null>(null);
    const [text, setText] = useState("");
    // const [context, setContext] = useState<ReadonlySet<number>>(new Set());
    const thumbsUpRef = useRef<HTMLButtonElement>(null);
    const thumbsDownRef = useRef<HTMLButtonElement>(null);

    // Popover.tsx keeps track of clicks outside the div element using useDetectClickOutside.
    // This function wraps #setShowFeedbackPopover with a Rest.post to submit feedback that is fired
    // when there is a click outside the popover boundary. This ensures the submission of rating
    // with no text for customer who dismiss the popover prompting to provide text feedback.
    const setShowPopoverWrapper: React.Dispatch<SetStateAction<boolean>> = (
        show: React.SetStateAction<boolean>,
    ): void => {
        if (!show && rating !== null) {
            onSubmit(rating);
            setText("");
        }
        setShowFeedbackPopover(show);
    };

    return (
        <div
            className={
                iconSize === IconSize.DEFAULT
                    ? "feedback__container--default"
                    : "feedback__container--small"
            }
        >
            <IconButton
                aria-label={"Up vote result"}
                ref={thumbsUpRef}
                onClick={() => {
                    if (rating === null || !rating) {
                        setRating(true);
                        // Popovers have a single target but here we are attaching it to two
                        // IconButtons. If thumbsDown button is active and the user clicks on
                        // thumbsUp button, Popover#setShow(false) is executed prior to the
                        // statement below because of setTimeout(even though it's a 0ms delay).
                        // The desired behavior of closing and attaching the popover to thumbsUp
                        // is achieved here.
                        setTimeout(() => setShowFeedbackPopover(true));
                    }
                }}
            >
                {rating === true ? (
                    <Icon.ThumbUpFilled size={iconSize} />
                ) : (
                    <Icon.ThumbUp size={iconSize} />
                )}
            </IconButton>
            <IconButton
                aria-label={"Down vote result"}
                ref={thumbsDownRef}
                onClick={() => {
                    if (rating === null || rating) {
                        setRating(false);
                        // See comment above for why we use setTimeout here.
                        setTimeout(() => setShowFeedbackPopover(true));
                    }
                }}
            >
                {rating === false ? (
                    <Icon.ThumbDownFilled size={iconSize} />
                ) : (
                    <Icon.ThumbDown size={iconSize} />
                )}
            </IconButton>
            <Popover
                aria-labelledby={"Provide text feedback."}
                placement={popoverPlacement}
                show={showFeedbackPopover}
                setShow={setShowPopoverWrapper}
                target={!rating ? thumbsDownRef : thumbsUpRef}
                renderOutsideParent={true}
            >
                <div className={"feedback__popover"}>
                    {/*{ contextOptions.length &&*/}
                    {/*    <StringMultiDropdownComponent*/}
                    {/*        options={contextOptions}*/}
                    {/*        values={context}*/}
                    {/*        setValues={setContext} /> }*/}
                    <TextArea
                        error={text.length > CHAR_LIMIT}
                        errorMessage={`Must be fewer than ${CHAR_LIMIT} characters`}
                        helper={popoverPlaceholder}
                        label={"Give additional feedback"}
                        onChange={(e) => setText(e.target.value)}
                        placeholder={
                            rating === false
                                ? thumbsDownTextBoxPlaceholder
                                : thumbsUpTextBoxPlaceholder
                        }
                        value={text}
                    />
                    <div className={"feedback__action-buttons"}>
                        <Button
                            aria-label={"Submit feedback"}
                            children={"Submit"}
                            color={ButtonColor.PRIMARY}
                            disabled={!text.length || text.length > CHAR_LIMIT}
                            onClick={() => {
                                setShowFeedbackPopover(false);
                                setText("");
                                if (rating !== null) {
                                    onSubmit(rating, text);
                                }
                            }}
                            size={ButtonSize.SMALL}
                            width={ButtonWidth.FLEXIBLE}
                        />
                        <Button
                            aria-label={"Close feedback"}
                            children={"Close"}
                            color={ButtonColor.SECONDARY}
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                setShowFeedbackPopover(false);
                                setText("");
                                if (rating !== null) {
                                    onSubmit(rating);
                                }
                            }}
                            width={ButtonWidth.FLEXIBLE}
                        />
                    </div>
                </div>
            </Popover>
        </div>
    );
}

interface StringMultiDropdownProps {
    options: string[];
    values: ReadonlySet<number>;
    setValues: Dispatch<SetStateAction<ReadonlySet<number>>>;
}

// Context multi-dropdown options to be added to the component once the bugs related to z-index
// and events are fixed.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function StringMultiDropdownComponent({
    options,
    values,
    setValues,
}: StringMultiDropdownProps): JSX.Element {
    const { dropdownProps, onItemClick, isItemSelected } = DropdownMenu.useMulti<number>({
        filterable: false,
        placeholder: "(Optional) Select codes",
        values: values,
        setValues: setValues,
    });

    const items = options.map((value, idx) => (
        <DropdownMenu.Checkbox
            key={idx}
            label={value}
            color={EverColor.EVERBLUE_30}
            value={isItemSelected(idx)}
            onChange={() => {
                onItemClick(idx);
            }}
        />
    ));

    return (
        <DropdownMenu {...dropdownProps} label={"Codes"}>
            {options.length ? (
                <DropdownMenu.Section header={"Codes"}>{items}</DropdownMenu.Section>
            ) : (
                <DropdownMenu.Supplement>No results</DropdownMenu.Supplement>
            )}
        </DropdownMenu>
    );
}
